import React, { useState } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
function TBVSetSchedMedicine({person, active}) {
    const [processing, setProcessing]                                   = useState(false);

    const handleMedicinePickNick = async () => {
        try{
            setProcessing(true);
            console.log("((()))))")
            console.log(CONSTANTS.API_URL + "pictures/set/medical/v2/");
             const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/set/medical/v2/" );
              console.log(responseUpdate)
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
   
    const handleMedicineAssignFalse = async () => {
        try{
            setProcessing(true);
            console.log("(((AC MILAN)))))")
         
             const responseUpdate = await axios.put(CONSTANTS.API_URL + "pictures/assign/medicine/v2/" );
              console.log(responseUpdate)
            setProcessing(false);
        }catch(err){
            console.log(err);
            setProcessing(false);
        }
    }
  return (
    <div className="tv-box">
        <h3>Update Medicine</h3>
        <div className="row rs-item">
            <div className="col-md-6">
               <p>Process the medicine fields</p>
               <button className="btn btn-picky" onClick={() => handleMedicinePickNick()}>
                    Process Now
                </button>
            </div>
            <div className="col-md-6">
               <p>Process the medicine fields</p>
               <button className="btn btn-picky" onClick={() => handleMedicineAssignFalse()}>
                   Assign Medicine false
                </button>
            </div>
        </div>
        {
            processing && (
                <div className="img-source">
                    <img src="https://newclient.cjdsa.co.za/images/base/cj_pros.gif" className="proc" />
                </div>
            )
        }
    </div>
  )
}

export default TBVSetSchedMedicine